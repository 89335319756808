import { configureStore } from '@reduxjs/toolkit'
import ordersReducer from './orders/orderSlice'
import userReducer from './user/userSlice'
import eventsReducer from './events/eventsSlice';

const store = configureStore({
  reducer: {
    orderStore: ordersReducer,
    userStore: userReducer,
    eventsStore: eventsReducer,
  },
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
