interface IRetry {
    fn: any
    retriesLeft?: number
    interval?: number
}

export function retry(fn: any, retriesLeft = 1, interval = 1000): Promise<any> {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error: any) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // reject('maximum retries exceeded');
                        reject(error)
                        window.location.reload()
                        return
                    }

                    // Passing on "reject" is the important part
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject)
                }, interval)
            })
    })
}
